<template>
  <layout-report>
    <router-view />
  </layout-report>
</template>

<script>
import LayoutReport from "@core/layouts/layout-vertical/LayoutReport.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    LayoutReport,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
