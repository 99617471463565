<template>
  <li v-if="item.isReport" name="reportLink" :code="item.CODE" :class="{ 'active': isActive, 'disabled': item.disabled}">
    <b-link @click="showReport(item)" class="d-flex align-items-center">
      <feather-icon :icon="item.ICON || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.NAME }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
  <li v-else class="nav-item" :class="{ 'active': isActive, 'disabled': item.disabled}">
    <b-link  v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.ICON || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.NAME }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods:{
    showReport(it){
      this.$router.replace({ name: 'report', query: { code: btoa(encodeURIComponent(JSON.stringify(it))) } })
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
